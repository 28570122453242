import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/fetch/api";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {},
        userPrincipalName: '',
        userMenuPrivilege: '',
    },
    getters: {
        userInfo: state => {
            return state.userInfo
        },
        userPrincipalName: state => {
            return state.userPrincipalName
        },
        userMenuPrivilege: state => {
            return state.userMenuPrivilege
        },
    },
    mutations: {
        setUserInfo: (state, data) => {
            state.userInfo = data
        },
        setUserId: (state, data) => {
            state.userPrincipalName = data
        },
        setUserMenuPrivilege: (state, data) => {
            state.userMenuPrivilege = data
        },
    },
    actions: {
        async getGetUserMenuPrivilege(context, userPrincipalName) {
            let res = await api.getUserMenuPrivilege({ userPrincipalName: userPrincipalName })
            context.commit('setUserMenuPrivilege', res.data)
        }
    },
    modules: {}
})