import { Request } from './http.js'

class Api extends Request {
    // 密码登录
    login(data) {
        return this.post('/login', data)
    }

    // 修改密码
    updatePassword(data) {
      return this.post('/user/d2suser/info/updatePassword', data)
  }
  
  // 更新头像
  updateAvater(data) {
    return this.post('/user/d2suser/info/updateAvater', data)
  }

  getLoginUrl(data) {
    return this.post('/account/getLoginUrl', data)
  }

}

export default new Api