import { Dialog, Confirm, Message } from 'element-ui';
var publicFun = function () {
  var self = {};
  self.pastSeconds = function (timestamp) {
    return Math.round(((new Date()).getTime() - timestamp.getTime()) / 1000);
  }
  self.jiequFun = function (str, beginIndex, endIndex) {
    let arr = str.split("");
    arr = arr.map((arrItem, arrIndex) => {
      if (arrIndex > beginIndex && arrIndex < endIndex) {
        arrItem = "*";
      }
      return arrItem;
    });
    return arr.join("");
  }
  self.message = function (msg, t) {
    let type;
    switch (t) {
      case 0: type = 'error'; break;
      case 1: type = 'success'; break;
      case 2: type = 'warning'; break;
      default: type = 'info';
    }
    Message({
      message: msg,
      type: type,
      center: true
    });
  };
  self.YearFun = function (timer) {
    let date = new Date(timer);
    let year = date.getFullYear();
    return `${year}`
  }
  self.DateFun = function (timer) {
    let date = new Date(timer);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`
  }
  self.MonthFun = function (timer) {
    let date = new Date(timer);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}`
  }
  self.getCron = function (type, data) {
    if (type == 1) {
      let now = new Date(data);
      let year = now.getFullYear();
      let month = now.getMonth() + 1 >= 10 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1);
      let day = now.getDate() >= 10 ? now.getDate() : '0' + (now.getDate());
      let hour = now.getHours() >= 10 ? now.getHours() : '0' + (now.getHours());
      let minutes = now.getMinutes() >= 10 ? now.getMinutes() : '0' + (now.getMinutes());
      let seconds = now.getSeconds() >= 10 ? now.getSeconds() : '0' + (now.getSeconds());

      // let cron = `${seconds} ${minutes} ${hour} ${day} ${month} ? ${year}-${year}`
      let cron = `${seconds} ${minutes} ${hour} ${day} ${month} ?`

      return cron
    } else if (type == 0) {
      let cron = ''
      let time = data.hour.split(':')
      let hour = time[0];
      let minutes = time[1];
      let seconds = time[2];
      let week = data.week.join(',')
      if (data.week.length == 7) {
        cron = `${seconds} ${minutes} ${hour} * * ?`
      } else {
        cron = `${seconds} ${minutes} ${hour} ? * ${week}`
      }

      return cron
    }
  }
  self.getTime = function (type, data) {
    if (type == 1) {
      let time = data.split(' ')
      let now = new Date();
      let year = now.getFullYear();
      let month = time[4];
      let day = time[3];
      let hour = time[2];
      let minutes = time[1];
      let seconds = time[0];

      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
    } else if (type == 0) {
      let time = data.split(' ')
      let hour = time[2];
      let minutes = time[1];
      let seconds = time[0];
      let arr = [hour, minutes, seconds];
      let week = []
      if (time[5] != '?') {
        week = time[5].split(',');
      } else {
        week = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
      }
      let obj = {
        week: week,
        hour: arr.join(':')
      }
      return obj
    }
  }
  self.myDate = function (arg, format) {
    let now = new Date(arg),
      year = now.getFullYear(),
      month = now.getMonth() + 1 >= 10 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1),
      day = now.getDate() >= 10 ? now.getDate() : '0' + (now.getDate()),
      hour = now.getHours() >= 10 ? now.getHours() : '0' + (now.getHours()),
      minutes = now.getMinutes() >= 10 ? now.getMinutes() : '0' + (now.getMinutes()),
      seconds = now.getSeconds() >= 10 ? now.getSeconds() : '0' + (now.getSeconds()),
      weekday = now.getDay(),
      today;
    switch (weekday) {
      case 1:
        today = '星期一';
        break;
      case 2:
        today = '星期二';
        break;
      case 3:
        today = '星期三';
        break;
      case 4:
        today = '星期四';
        break;
      case 5:
        today = '星期五';
        break;
      case 6:
        today = '星期六';
        break;
      default:
        today = '星期日';
        break;
    }

    if (format == 'date') {
      return `${year}-${month}-${day}`;
    } else if (format == 'month') {
      return `${year}${month}`;
    } else if (format == 'second') {
      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
    } else if (format == 'week') {
      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds} ${today}`;
    } else if (format == 'hour') {
      return `${hour}:${minutes}:${seconds}`;
    } else if (format == 'today') {
      return `${today}`;
    }
    return `${year}-${month}-${day} ${hour}:${minutes}`;

  }
  // self.MonSunday=function(){
  //   var now = new Date();
  //   var nowTime = now.getTime() ;
  //   var day = now.getDay();
  //   var oneDayTime = 24*60*60*1000 ;
  //   var MondayTime = nowTime - (day-1)*oneDayTime ;//显示周一
  //   var SundayTime =  nowTime + (7-day)*oneDayTime ;//显示周日
  // }
  return self;
}();
export {
  publicFun
}
