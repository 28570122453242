import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index' // 引入store


Vue.use(VueRouter)
    // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'guide',
        component: () =>
            import ( /* webpackChunkName: "guide" */ '../components/guide.vue'),
        meta: {
            title: '导航'
        }
    },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: () =>
    //         import ( /* webpackChunkName: "login" */ '../components/Login.vue'),
    //     meta: {
    //         title: '登录'
    //     }
    // },
    // {
    //     path: '/loginSure',
    //     name: 'LoginSure',
    //     component: () =>
    //         import ( /* webpackChunkName: "loginSure" */ '../components/LoginSure.vue'),
    //     meta: {
    //         title: '登录确认'
    //     }
    // },
    // {
    //     path: '/error',
    //     name: 'error',
    //     component: () =>
    //         import ( /* webpackChunkName: "loginSure" */ '../components/error.vue'),
    //     meta: {
    //         title: '404'
    //     }
    // },
    
]

const router = new VueRouter({
    base: process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_PUBLICPATH,
    // base: baseURL,
    mode: 'history', // require service support
    routes,
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return {
    //         x: 0,
    //         y: 0
    //         }
    //     }
    // }
})

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    document.title = '导航'
    // 如果是前往登录页面 ,则放行
    // 判断是否登录, 如果没有登录则要重定向到 登录页面
    // SWITCH 环境切换
    next();
});
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});
export default router