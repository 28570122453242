import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style/flex.css";
import "@/assets/style/common.less";
import { instance } from '@/fetch/http.js'
Vue.use(ElementUI);

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
require('es6-promise').polyfill();
require('es6-promise/auto');

 
Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    methods: {
		// 导出
		exportGetFile(url, name, params) {
			instance({
				method: 'get',
				url: url,
				params: params,
				responseType: 'blob'
			}).then(res => {
				if (!res) {
					return
				}
				let url = window.URL.createObjectURL(res)
				let link = document.createElement('a')
				link.style.display = 'none'
				link.href = url;
				link.setAttribute('download', name + '.xlsx')
				document.body.appendChild(link)
				link.click()
			})
		},
		exportPostFile(url, name, params) {
			instance({
				method: 'post',
				url: url,
				data: params,
				responseType: 'blob'
			}).then(res => {
				if (!res) {
					return
				}
				let url = window.URL.createObjectURL(res)
				let link = document.createElement('a')
				link.style.display = 'none'
				link.href = url;
				link.setAttribute('download', name + '.xlsx')
				document.body.appendChild(link)
				link.click()
			})
		}
	}
}).$mount('#app')